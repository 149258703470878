<template>
    <div class="ui-simple-rich-text" data-test="ui-simple-rich-text" :style="componentStyles">
        <div class="rich-text" :class="`text-${blok.textAlignment}`">
            <rich-text :content="blok.richText" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { VNode } from 'vue';
import { createTextVNode, h } from 'vue';
import { StoryblokComponent } from '@storyblok/vue';
import {
    BlockTypes,
    richTextResolver,
    type StoryblokRichTextNode,
    type StoryblokRichTextOptions,
} from '@storyblok/richtext';

import type { ICoreSimpleRichText } from '~ui/types/components/CoreSimpleRichText';

const { blok } = defineProps<{ blok: ICoreSimpleRichText }>();
const { render } = useStoryblokRichText({});
const componentStyles = computed(() => ({
    'padding-top': blok.paddingTop ? `${blok.paddingTop}px` : null,
    'padding-right': blok.paddingRight ? `${blok.paddingRight}px` : null,
    'padding-bottom': blok.paddingBottom ? `${blok.paddingBottom}px` : null,
    'padding-left': blok.paddingLeft ? `${blok.paddingLeft}px` : null,
    '--line-height': blok.lineHeight ? `${blok.lineHeight}px` : null,
}));

const options: StoryblokRichTextOptions<VNode> = {
    renderFn: h,
    textFn: createTextVNode,
    resolvers: {
        [BlockTypes.COMPONENT]: (node: StoryblokRichTextNode<VNode>) => {
            return node?.attrs?.body.map((blok) => {
                return h(StoryblokComponent, {
                    blok: blok,
                });
            });
        },
    },
};

const richText = (content: any) => {
    return richTextResolver<VNode>(options).render(content.content);
};
</script>

<style scoped lang="postcss">
.ui-simple-rich-text {
    &:deep(.css-float-left) {
        @apply float-left pr-2.5;
    }
    &:deep(.css-hide-desktop) {
        @apply md:hidden;
    }
    &:deep(.css-float-right) {
        @apply float-right pl-2.5;
    }
    &:deep(.css-text-xs) {
        @apply text-xs;
    }
    &:deep(.css-text-sm) {
        @apply text-sm;
    }
    &:deep(.css-text-md) {
        @apply text-base;
    }
    &:deep(.css-text-lg) {
        @apply text-lg;
    }
    &:deep(.css-text-xl) {
        @apply text-xl;
    }
    &:deep(.css-text-2xl) {
        @apply text-2xl;
    }
    &:deep(.css-text-3xl) {
        @apply text-3xl;
    }
    &:deep(.css-text-4xl) {
        @apply text-4xl;
    }
    &.text-left-align {
        @apply text-left;
    }
    &.text-center-align {
        @apply text-center;
    }
    &.text-right-align {
        @apply text-right;
    }
    &:deep(ul) {
        &:has(.css-list-checkmarks) {
            @apply list-none;
        }
        li {
            span:first-child.css-list-checkmarks:after {
                @apply content-["\2714"] block absolute -left-5 -top-1 text-base;
                font-family: 'Font Awesome 6 Duotone';
            }

            span:first-child.css-list-checkmarks {
                @apply relative pl-2;
            }
        }
    }

    :deep(.richtext-checkmark-list) {
        font-size: var(--checkmark-list-font-size);
        @apply ml-0 list-none;
        svg.checkbox-circle-line {
            height: var(--checkmark-size);
            margin-right: calc(var(--checkmark-size) / 2);
        }
        li {
            @apply mb-2.5 flex flex-nowrap items-start;
        }
    }
}
</style>
